<script>
import MajorSummary from '/~/extensions/gift-cards-cinema/components/major-summary.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import ProductHeader from './product-header.vue'

export default {
  name: 'gift-cards-cinema-location-major-summary-bupa',
  components: {
    ProductHeader,
    BaseButton,
  },
  extends: MajorSummary,
}
</script>

<template>
  <div class="relative w-full">
    <product-header
      class="mt-2.5 font-semibold"
      :retailer="gcCinemaMajorRetailer"
    />

    <div
      class="mt-5 flex flex-col justify-around bg-light px-[30px] py-[15px] md:flex-row md:justify-between md:px-5"
    >
      <div v-if="gcCinemaMajorProducts" class="flex flex-col space-y-[15px]">
        <div
          v-for="(product, idx) in gcCinemaMajorProducts"
          :key="idx"
          class="flex items-center justify-evenly md:justify-start"
        >
          <div class="mr-2.5 flex w-[120px] items-center">
            {{ formatDollar(product.price) }}
          </div>
          <base-counter
            v-model="product.count"
            :disabled="!product.in_stock || isGcCinemaMajorProcessing"
            :min="product.min"
            :max="product.max || 10"
          />
        </div>
      </div>
      <product-total
        :amount="gcCinemaMajorTotal"
        class="mt-2.5 md:ml-5 md:mt-0"
      />
    </div>

    <base-alert
      v-if="disclaimer"
      title="Disclaimer"
      type="warning"
      look="light-filled"
      class="mt-5"
    >
      <span class="markup text-justify" v-html="disclaimer" />
    </base-alert>

    <retailer-gift
      v-if="isGiftingEnabled"
      ref="retailerGift"
      v-model="recipient"
      :visible.sync="gift.visible"
      :filled.sync="gift.filled"
      :disabled="isGcCinemaMajorProcessing"
      :product-labels="productLabels"
      :backend-errors="backendErrors"
      class="my-6 px-5 sm:p-0"
    />

    <div class="mt-[30px] flex justify-end space-x-5 px-5">
      <base-button
        v-if="isAddToCartEnabled"
        :disabled="isSubmitDisabled"
        :loading="isGcCinemaMajorProcessing"
        :look="isQuickBuyEnabled ? 'outlined-color' : 'filled'"
        @click="onSubmit"
      >
        Add to cart
      </base-button>
      <base-button
        v-if="isQuickBuyEnabled"
        :disabled="isSubmitDisabled || gift.visible"
        class="flex-1 sm:flex-none"
        @click="onBuyNow"
      >
        Buy Now
      </base-button>
    </div>
  </div>
</template>
